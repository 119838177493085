@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.statistics-page {
  padding: 20px;
}

.statistics-page h1 {
  text-align: center;
}

/* Добавьте стили для карточек в Card_statistics.css */
